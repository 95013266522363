import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import { color } from "../utils/style";
import Img from "./image"

const linkStyle = css({
  transition: '.3s ease-in-out',
  display: 'flex',
  position: 'relative',
  padding: '5px',
  textDecoration: 'none',
  color: '#333',
  cursor: 'pointer',
  boxShadow: '0 1px 5px 0 rgba(0,0,0,.25)',
  '&:hover': {
    boxShadow: '0 5px 15px rgba(0,0,0,.25)',
    color: '#888',
    textDecoration: 'none',
    transform: 'translateY(-2px)',
  },
  marginBottom: '10px',
  '.linkto-title' : {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    textDecoration: 'underline',
    marginBottom: '2px',
  },
  '.linkto-img': {
    alignSelf: 'center',
    '&::before': {
      position: 'absolute',
      content: '"関連リンク"',
      lineHeight: '30px',
      fontSize: '1.8rem',
      fontWeight: 'bold',
      padding: '0px 10px',
      marginBottom: '6px',
      zIndex: '1',
      bottom: '0px',
      background: color.main,
      color: 'white',
    },
    flex: '1 1 120px',
    '&:hover': {
      boxShadow: '0 4px 5px 0 rgba(0,0,0,.25)',
    }
  },
  '.linkto-description': {
    flex: '2 0 160px',
    paddingLeft: '10px',
  },
  '.linkto-next': {
    flex: '0 0 35px',
    height: '35px',
    position: 'relative',
    alignSelf: 'center',
  },
  '.linkto-arrow': {
    position: 'absolute',
    width: '15px',
    height: '15px',
    borderTop: '4px solid #333',
    borderRight: '4px solid #333',
    transform: 'rotate(45deg)',
    top: '10px',
    left: '5px',
  }
})

export default function Card(props) {
  const { title, description, image, path, className } = props;
  return (
    <Link to={path} css={linkStyle} className={className}>
      <div className="linkto-img">
        <Img {...props} name={image} alt={title} isCard/>
      </div>
      <div className="linkto-description">
        <div className="linkto-title">{title}</div>
        <div className="linkto-detail">{description}</div>
      </div>
      <div className="linkto-next">
        <div className="linkto-arrow"></div>
      </div>
    </Link>
  )
}

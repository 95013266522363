import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";

const Wrapper = styled.div({
  fontSize: '1.5rem',
  padding: '10px',
  backgroundColor: '#fdfdfa',
  borderRadius: '4px',
  boxSizing: 'border-box',
  border: '2px solid #bcc8c9',
  width: '100%',
  marginBottom: '15px',
  '&.simple' : {
    backgroundColor: 'white',
    border: '2px solid #26a69b',
    '.accent-box-title' : {
      borderBottom: '1px solid #26a69b',
    }
  },
  [mq('mobile')] : {
    marginRight: '10px',
    marginLeft: '10px',
    width: 'calc(100% - 20px)',
  },
});

const Title = styled.div({
  textAlign: 'center',
  fontWeight: 700,
  borderBottom: '1px solid #bcc8c9',
  paddingBottom: '.5rem',  
});

const List = styled.div({
  listStyle: 'none',
  margin: '0px',
  paddingLeft: '20px',
  li: {
    marginTop: '6px',
    position: 'relative',
    '&:first-of-type': {
      marginTop: '12px',
    }
  },
  '&.dia li::before': {
    display: 'block',
    content: '""',
    position: 'absolute',
    top: '.4em',
    left: '-1em',
    width: '6px',
    height: '6px',
    backgroundColor: color.main,
    borderLeft: `2px solid ${color.main}`,
    borderBottom: `2px solid ${color.main}`,
    transform: 'rotate(-45deg)',
  },
  '&.check': {
    'li:not(.bad)::before': {
      display: 'block',
      position: 'absolute',
      content: '""',
      top: '.4em',
      left: '-1.2em',
      width: '10px',
      height: '4px',
      borderLeft: '3px solid #44ADA6',
      borderBottom: '3px solid #44ADA6',
      transform: 'rotate(-45deg)',
    },
    'li.bad::before': {
      display: 'block',
      position: 'absolute',
      content: '"×"',
      fontSize: '2rem',
      color: 'red',
      top: '-3px',
      left: '-20px',
    }
  },
});

export default function AccentBox(props) {
  return (
    <Wrapper>
      {props.title && <Title>{props.title}</Title>}
      <List className={props.type || 'dia'}>
        {props.children}
      </List>
    </Wrapper>
  );
};
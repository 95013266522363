import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import Youtube from "components/youtube";
import { graphql } from "gatsby";
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  BasicTable,
  Youtube,
  graphql,
  React
};
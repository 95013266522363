import React from "react"
import styled from "@emotion/styled"
import { mq } from "../utils/style";

const aColor = 'rgb(250, 134, 2)';
const rColor = 'rgb(226, 20, 20)';

const AdWrapper = styled.div({
  [mq('pc')]: {
    textAlign: 'left',
  },
  textAlign: 'center',
  margin: '15px 0px',
  a: {
    display: 'inline-block',
    borderRadius: '5px',
    lineHeight: '3.5rem',
    fontSize: '1.7rem',
    width: '240px',
    maxWidth: 'calc(49% - 20px)',
    position: 'relative',
    backgroundColor: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '15px',
      width: '6px',
      height: '6px',
      margin: '-4px 0 0 0',
      transform: 'rotate(45deg)',
    },
    '&.adbtn-amazon': {
      border: `solid 2px ${aColor}`,
      color: aColor,
      '&::after':{
        borderTop: `solid 2px ${aColor}`,
        borderRight: `solid 2px ${aColor}`,
      }
    },
    '&.adbtn-rakuten': {
      marginLeft: '5px',
      border: `2px solid ${rColor}`,
      color: rColor,
      '&::after': {
        borderTop: `2px solid ${rColor}`,
        borderRight: `2px solid ${rColor}`,
      }
    }
  }
});

export default function FlatButton(props) {
  return (
    <AdWrapper>
      <a href={props.alink} target="_blank" rel="noopener nofollow" className="adbtn-amazon">Amazonで見る</a>
      <a href={props.rlink} target="_blank" rel="noopener nofollow" className="adbtn-rakuten">楽天で見る</a>
    </AdWrapper>
  );
}
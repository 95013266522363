import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";
import { navigate } from '@reach/router';

const TOCWrapper = styled.div({
  background: color.mainThin,
  padding: '10px 0px',
  '.toc-title': {
    background: color.main,
    borderRadius: '4px',
    color: 'white',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '10px',
    padding: '2px',
  },
  ol: {
    counterReset: 'section', /* 各 ol 要素に新しいインスタンスの section カウンタを生成 */
    listStyleType: 'none',
    '&.toc-first-level': {              
      paddingLeft: '0px',
      '> li': {
        '&:before': {
          counterIncrement: 'section',
          //content: counter(section)". ",   /* "." で区切られた section カウンタのインスタンスの値を付加 */
          content: 'counter(section)',
          display: 'inline-block',
          marginRight: '5px',
          width: '30px',
          lineHeight: '30px',
          textAlign: 'center',
          borderRadius: '50%',
          backgroundColor: color.main,
          fontWeight: 'bold',
          color: '#fff',
        }
      }
    },
    '&.toc-second-level': {
      fontSize: '1.5rem',
      li: {
        '&:before': {
          counterIncrement: 'section',
          content: 'counters(section, "-") " "',   /* "." で区切られた section カウンタのインスタンスの値を付加 */
        }
      }
    },
  },
  '.link': {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
      color: '#ff8000',
      textDecoration: 'underline',
    }
  },
  [mq('mobile')]: {
    boxShadow: '0px 0px 4px 3px #eee',
    margin: '0px 10px',
    ol: {
      margin: '5px 8px',
      '&.toc-second-level': {
        paddingLeft: '0px',
      },
    },
    li: {
      paddingTop: '10px',
    }
  },
  [mq('pc')]: {
    boxShadow: '0px 0px 3px 2px #eee',
    ol : {
      margin: '5px 15px',
      '&.toc-second-level': {
        paddingLeft: '15px',
      },
      li: {
        paddingTop: '8px',
      }
    },
  }
})

export default class TOC extends React.Component {
  constructor(props) {
    super(props);
    const headings = props.headings || props.data.allMdx.nodes[0].headings;
    this.headGroups = headings.reduce((acc, { depth, value }) => {
      if (depth === 2) {
        acc.push([value]);
      } 
      if (depth === 3) {
        acc[acc.length -1].push(value);
      }
      return acc;
    }, []);
  }

  onClick = (path) => {
    navigate(`#${path.replace(/ |　/g,'-').replace(/[%().+,/|><?&:]/g,'')}`);
  }

  render () {
    return (
      <TOCWrapper>
        <div className="toc-title">この記事の目次</div>
        <ol className="toc-first-level">
          {
            this.headGroups.map((headGroup, i) => {
              if (headGroup.length === 1) {
                return <li key={i} onClick={() => this.onClick(headGroup[0])}><span className="link section">{headGroup[0]}</span></li>
              } else {
                return (
                  <li key={i}><span className="link section" onClick={() => this.onClick(headGroup[0])}>{headGroup[0]}</span>
                    <ol className="toc-second-level">
                      {headGroup.map((head, k) => {
                        if (k === 0) {
                          return null;
                        } else {
                          return <li key={k} onClick={() => this.onClick(head)}><span className="link">{head}</span></li>
                        }
                      })}
                    </ol>
                  </li>
                )
              }
            })
          }
        </ol>
      </TOCWrapper>
    );
  }
}
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import * as React from 'react';
export default {
  Link,
  graphql,
  HikakuTable,
  AccentBox,
  Image,
  Socials,
  BasicTable,
  BenchMark,
  CpuMatsubi,
  HowtoFilter,
  TableDescription,
  PassMark,
  React
};
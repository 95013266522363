import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";

const Wrapper = styled.div({
  position: 'relative',
  padding: '16px 10px',
  border: `2px solid ${color.blue}`,
  backgroundColor: color.baseMat,
  '&::before': {
    position: 'absolute',
    bottom: '100%',
    left: '-2px',
    padding: '2px 6px',
    backgroundColor: color.blue,
    color: color.baseMat,
    fontWeight: 'bold',
  },
  '&.link': {
    '&::before': {
      content: '"関連リンク"',
    }
  },
  '&.word': {
    border: `2px solid ${color.blue}`,
    '&::before': {
      backgroundColor: color.blue,
      content: 'attr(data-text)',
    },
  },
  '&.accent' : {
    border: `2px solid ${color.accent}`,
    '&::before': {
      backgroundColor: color.accent,
    },
  },
  [mq('mobile')] : {
    margin: '36px 10px 0px 10px',
  },
  [mq('pc')] : {
    marginTop: '50px',
  },
});

export default function SmallBox(props) {
  return (
    <Wrapper className={props.type} data-text={props.text}>
      {props.children}
    </Wrapper>
  );
};
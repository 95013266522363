import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import IntelMatsubi from "components/cpu/intel_matsubi";
import AMDMatsubi from "components/cpu/amd_matsubi";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  SmallBox,
  AccentBox,
  BasicTable,
  IntelMatsubi,
  AMDMatsubi,
  Link,
  graphql,
  Card,
  React
};
import React, { useState } from 'react';
import styled from "@emotion/styled"
import { color } from "../utils/style";

const Wrapper = styled.div({
  margin: '2px 0 0 0',
});

const Head = styled.div({
  backgroundColor: color.main,
  color: 'white',
  cursor: 'pointer',
  margin: '0 0 0 0',
  padding: '7px 11px',
  fontWeight: 'bold',
  '.plusminus': {
    float: 'right',
  }
});

const Body = styled.div({
  padding: '0px 8px',
  margin: '0px',
  borderLeft: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #CCCCCC',
});

const open = {
  maxHeight: '3000px',
  overflow: 'hidden',
  transition: 'max-height 1s linear',
};

const premium = {
  backgroundColor: 'black',
  color: 'gold',
};

const premiumBody = {
  color: 'gold',
};

const close = {
  maxHeight: '0px',
  overflow: 'hidden',
  padding: 0,
};

export default function Accordion(props) {
  const [isOpen, toggleOpen] = useState(false);
  let bodyStyle = isOpen ? open : close;
  if (props && props.premium) {
    bodyStyle = { ...bodyStyle, ...premiumBody};
  }
  return (
    <Wrapper>
      <Head
        onClick={() => toggleOpen(!isOpen)}
        style={props.premium && premium}
      >
        {props.title}<span className="plusminus">{isOpen ? '-' : '+'}</span>
      </Head>
      <Body style={bodyStyle}>{props.children}</Body>
    </Wrapper>
  );
}
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import Twenty from "react-twentytwenty";
import List from "components/list";
import Youtube from "components/youtube";
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  AccentBox,
  BasicTable,
  Card,
  Link,
  graphql,
  Twenty,
  List,
  Youtube,
  React
};
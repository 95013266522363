import React from "react"
import styled from "@emotion/styled"
import fbIcon from "./images/fb_icon.png";
import hatebuIcon from "./images/hatebu_icon.png";
import lineIcon from "./images/line_icon.png";
import twIcon from "./images/tw_xicon.png";

const SocialBar = styled.div({
  margin: '5px 0px 10px',
  height: '35px',
  '> div' : {
    float: 'left',
    width: '25%',
  },
  a : {
    display: 'block',
    height: '35px',
    lineHeight: '35px',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '.clear' : {
    clear: 'both',
  },
  '.share-fb' : {
    background: `#315096 url(${fbIcon}) center no-repeat`,
  },
  '.share-tw' : {
    background: `#000000 url(${twIcon}) center no-repeat`,
  },
  '.share-hatena' : {
    background: `#008fde url(${hatebuIcon}) center no-repeat`,
  },
  '.share-line' : {
    background: `#00c300 url(${lineIcon}) center no-repeat`,
  },
});

const siteUrl = "https://pcrecommend.com";

export default function Socials(props) {
  const pageUrl = encodeURIComponent(siteUrl + props.location.pathname);
  const heading = props.data.allMdx.nodes[0].headings[0].value;
  const twitterText = heading && heading.replace('|', '');
  return (
    <SocialBar>
      <div className="share-fb"><a href={`https://www.facebook.com/sharer.php?u=${pageUrl}`} target="_blank" rel="nofollow noopener noreferrer"></a></div>
      <div className="share-tw"><a href={`https://twitter.com/share?url=${pageUrl}&lang=ja&text=${twitterText}`} target="_blank" rel="nofollow noopener noreferrer"></a></div>
      <div className="share-hatena"><a href={`https://b.hatena.ne.jp/add?mode=confirm&url=${pageUrl}&title=${heading}`} target="_blank" rel="nofollow noopener noreferrer"></a></div>
      <div className="share-line">
        <a href={`https://timeline.line.me/social-plugin/share?url=${pageUrl}`} target="_blank" rel="nofollow noopener noreferrer" className="line_pc"></a>
      </div>
      <div className="clear"></div>
    </SocialBar>
  );
};
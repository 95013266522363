import Image from "components/image";
import TOC from "components/toc";
import MakerSaleTable from "components/makerSaleTableLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import ScrollTable from "components/scrollTable";
import SalesTweetList from "components/salesTweetListLoadable";
import { graphql } from "gatsby";
import Card from "components/card";
import * as React from 'react';
export default {
  Image,
  TOC,
  MakerSaleTable,
  Socials,
  BasicTable,
  ScrollTable,
  SalesTweetList,
  graphql,
  Card,
  React
};
import React from 'react';
import AccentBox from '../accentBox'
import Accordion from '../accordion'

export default function HowToFilter() {
  return (
    <Accordion title="検索/フィルタリング方法の例・詳細">
      <p>
      ヘッダー部分をクリックすることでソートが行える。また、CPU名称はカンマ区切りをすることでor条件で調べられ、各スコアはレンジ(-)や不等号でフィルタリングすることができる。
      </p>
      <AccentBox title="フィルター方法の例(該当するフォームへ入力)">
        <li><em>i5-13,i5-12,i5-11</em>・・・第13世代と第12世代と第11世代のCore i5を比較</li>
        <li><em>Ryzen 7 5,i7-13</em>・・・第5世代Ryzen7と第13世代Core i7を比較</li>
        <li><em>core.*-12</em>・・・第12世代のCPU。<em>.*</em>はワイルドカードで任意の文字列が入る</li>
        <li><em>core.*-13.*K$</em>・・・第13世代のCPU。<em>$</em>は終端文字で最後がKで終わる</li>
        <li><em>1 - 20000</em>・・・スコアが1から20000まで、-の間にはスペースが必要</li>
        <li><em>{'<'}50</em>・・・TDPが50W未満の省電力CPUのみフィルタ</li>
        <li><em>{'>'}=4</em>・・・コア数が４以上</li>
        <li><em>{'>'}100</em>・・・参考価格が100ドルより上</li>
      </AccentBox>
    </Accordion>
  );
}
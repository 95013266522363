import React from 'react';
import AccentBox from '../accentBox'

export default function GeforceMatsubi() {
  return (
    <AccentBox title="Geforceの末尾文字">
      <li><span className="bold">Ti</span>・・・ Titanium(チタン)の略、最高峰を意味する金属記号としてTitaniumは度々登場。通常版より高性能</li>
      <li><span className="bold">SUPER</span>・・・スーパー。通常より高性能。ただしSuper {'<'} Ti</li>
      <li><span className="bold">Mobile</span>・・・ノート用GPU、消費電力が低い</li>
      <li><span className="bold">Max-Q</span>・・・薄型ノート用GPU、さらに消費電力が低い</li>
      <li><span className="bold">MX</span>・・・ノートパソコン向けエントリーモデル</li>
    </AccentBox>
  );
}
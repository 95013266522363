import React from 'react'
import Accordion from '../accordion'
import AccentBox from '../accentBox'
import BasicTable from '../basicTable'

export default function Benchmark() {
  return (
    <Accordion title="PassMarkについて">
      <p><strong>PassMark</strong>は重い計算処理を回す、動画のレンダリングを行うなど、CPUが様々な使われ方をされた場合の総合的な性能を評価している。この<strong>値が高いほどCPUが総合的に高速</strong>であるといえる。</p>
      <p>当サイトではCPUの全てのコアを使用した総合的な性能であるPassMark(M)と一つのみを用いたPassMark(S)のスコアを併記している。Mはマルチコアの略であり、Sはシングルコアの略である。それぞれの特徴は下記の用になる。</p>
      <BasicTable>
        <thead>
          <tr>
            <th>有利なコア</th>
            <th>具体的なタスク</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>シングルコア</td>
            <td>アプリの起動、Webブラウジングのサクサク感、ゲーム</td>
          </tr>
          <tr>
            <td>マルチコア</td>
            <td>複数アプリの同時実行、ファイルの解凍・圧縮、動画のエンコード・デコード、AI処理、画像のレンダリング</td>
          </tr>
        </tbody>
      </BasicTable>
      <p>スコアは毎年新規のCPUが出るたびに少しずつ上がるが、スコア自体が何を意味するかというものではない。<strong>新旧世代の製品や、シリーズ違いの相対比較に用いるのが良い</strong>。</p>
      <p>なお、ソースとしてPassMarkの値は下記を参照している。</p>
      <AccentBox title="ベンチマークの参照ページ">
        <li><a href="https://www.cpubenchmark.net/share30.html" target="_blank" rel="noreferrer">PassMark - CPU Popularity</a>・・・メジャーCPUの総合性能評価。定番以外は<a href="https://www.cpubenchmark.net/" target="_blank" rel="noreferrer">トップページ</a>から参照</li>
      </AccentBox>
    </Accordion>
  );
}
import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div({
  position: 'relative',
  paddingBottom: '56.25%', /*アスペクト比 16:9の場合の縦幅*/
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }
});

export default function Youtube(props) {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  );
};
import React from 'react'
import Accordion from '../accordion'
import AccentBox from '../accentBox'

export default function Benchmark(props) {
  return (
    <Accordion title="Cinebenchについて">
      <p>Cinebenchは3D動画編集などクリエイティブな作業におけるパフォーマンスとして主に参考にされる値である。レンダリングという光の反射等を計算して最終的な一枚絵を作り出す時の性能を評価している。</p>
      <p>スコアは毎年新規のCPUが出るたびに少しずつ上がるが、スコア自体が何を意味するかというものではない。<strong>新旧世代の製品や、シリーズ違いの相対比較に用いるのが良い</strong>。</p>
      <p>また、本サイトではデータソースとして下記を参照している。</p>
      {props && props.c23 &&<AccentBox title="ベンチマークの参照ページ">
        <li><a href="https://www.cpu-monkey.com/ja/cpu_benchmark-cinebench_r23_multi_core-16" target="_blank" rel="noreferrer">Cinebench R23 (Multi-Core)</a>・・・独MAXSON社が提供する有名ベンチマーク、シネベンチ。動画処理系や複数アプリの実行に強いマルチスレッド性能を計測</li>
        <li><a href="https://www.cpu-monkey.com/ja/cpu_benchmark-cinebench_r23_single_core-15" target="_blank" rel="noreferrer">Cinebench R23 (Single-Core)</a>・・・シネベンチのシングルスレッド性能計測、ゲームやアプリケーションの起動などはこちらが大きく影響。ただ、バックグランドの処理がデータ計測に与える影響も大きく、データの信頼性はやや欠ける</li>
      </AccentBox>}
      {props && props.c2024 &&<AccentBox title="ベンチマークの参照ページ">
        <li><a href="https://www.cpu-monkey.com/ja/cpu_benchmark-cinebench_2024_multi_core" target="_blank" rel="noreferrer">Cinebench 2024 (Multi-Core)</a>・・・独MAXSON社が提供する有名ベンチマーク、シネベンチ。動画処理系や複数アプリの実行に強いマルチスレッド性能を計測</li>
        <li><a href="https://www.cpu-monkey.com/ja/cpu_benchmark-cinebench_2024_single_core" target="_blank" rel="noreferrer">Cinebench 2024 (Single-Core)</a>・・・シネベンチのシングルスレッド性能計測、ゲームやアプリケーションの起動などはこちらが大きく影響。ただ、バックグランドの処理がデータ計測に与える影響も大きく、データの信頼性はやや欠ける</li>
      </AccentBox>}
    </Accordion>
  );
}
import React from 'react';
import AccentBox from '../accentBox'
import Accordion from '../accordion'

export default function GpuMeyasu() {
  return (
    <Accordion title="GPU性能比較表の項目別説明/FPSの目安">
      <p>この比較表では、GPU名、Passmark、3DMark、FullHD、QHD、4KのFPS、TDP、コスパ、価格の項目が掲載されているが、それぞれについて注意点を簡単に掲載しておく。表を見て比較する時の参考として欲しい。</p>
      <AccentBox title="グラフィックボード性能比較表での注目・注意点">
        <li><span className="bold">Passmark</span>・・・グラフィックボードの総合的な性能を多面的に計測するテスト、サンプル数が多く信頼性は抜群。</li>
        <li><span className="bold">3DMark</span>・・・3D MarkのうちGPUのテストであるTime Spy Graphics Scoreを掲載。高いほどゲームに向き、フレームレートが出やすい</li>
        <li><span className="bold">FHD FPS</span>・・・FullHD(1920x1080)解像度でプレイした時、やや重めのゲームの最高画質で出せる平均FPS、重いゲームだと高画質でその程度</li>
        <li><span className="bold">QHD FPS</span>・・・QHD(2560x1440)解像度でプレイした時、やや重めのゲームの最高画質で出せる平均FPS、重いゲームだと高画質でその程度</li>
        <li><span className="bold">4K FPS</span>・・・4K(3840x2160)解像度でプレイした時、やや重めのゲームの最高画質で出せる平均FPS、重いゲームだと高画質でその程度</li>
        <li><span className="bold">TDP</span>・・・熱設計電力を表し、消費電力の目安。この2倍+100W程度がPSU(電源ユニット)の推奨となる。</li>
        <li><span className="bold">コスパ</span>・・・3D Markの結果を価格で割った値。概ねハイエンドはコスパが悪い</li>
        <li><span className="bold">価格</span>・・・単位はドル、アメリカでの参考販売価格。日本だとおおよそ120程度を掛けた値となる。輸入価格が上乗せされやや割高な感がある</li>
      </AccentBox>
      <p>FPS(フレームレート)は１秒間に描画される絵の枚数であり、高いほど映像が滑らかである。目安としては下記である。</p>
      <AccentBox title="FPSの目安">
        <li><span className="bold">144FPS</span>・・・ヌルヌル動いて残像も少ない、ただし60FPSを超えると対応するディスプレイが必要</li>
        <li><span className="bold">60FPS</span>・・・非常に快適にプレイできる</li>
        <li><span className="bold">45FPS</span>・・・それなりに快適にプレイできる</li>
        <li><span className="bold">30FPS</span>・・・普通にプレイできる、画質や解像度を落としてFPSを上げるのもあり</li>
      </AccentBox>
    </Accordion>
  );
}
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import FlatButton from "components/flatbutton";
import * as React from 'react';
export default {
  Link,
  graphql,
  HikakuTable,
  AccentBox,
  Accordion,
  Image,
  Socials,
  TOC,
  BasicTable,
  BenchMark,
  IntelMatsubi,
  HowtoFilter,
  TableDescription,
  PassMark,
  FlatButton,
  React
};
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableLoadable';
import Accordion from 'components/accordion';
import Socials from 'components/socials';
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import Card from "components/card";
import * as React from 'react';
export default {
  Link,
  graphql,
  HikakuTable,
  Accordion,
  Socials,
  BenchMark,
  CpuMatsubi,
  HowtoFilter,
  TableDescription,
  PassMark,
  Card,
  React
};
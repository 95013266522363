import React from 'react'
import Accordion from '../accordion'
import AMDMatsubi from './amd_matsubi'
import IntelMatsubi from './intel_matsubi'

export default function CPUMatsubi() {
  return (
    <Accordion title="CPU型番の読み方、末尾の意味一覧">
      <p>リストにより性能を比較する前に、前提条件としての型番の読み方を抑えておこう。</p>
      <p>例としてIntel Core i9 13900Kのi9はCPUのシリーズ、13900の最初の13は世代、後ろの900はマイナーバージョンを表す。世代番号が大きいほど最新のモデルで、マイナーバージョンが大きいほど高性能である。</p>
      <p>同様にAMD Ryzen 9 7950Xの最初の9はシリーズ、7950の7が世代、950がマイナーバージョンである。お尻のKやXは種別を表し、Intel、AMDでそれぞれ下記を意味する。</p>
      <IntelMatsubi/>
      <AMDMatsubi/>
    </Accordion>
  );
}
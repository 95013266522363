export const mq = device => {
  if (device === 'mobile') {
    return '@media (max-width: 499px)';
  } else if (device === 'pc') {
    return '@media (min-width: 500px)'
  }
};

export const pageWidth = '1000px';
export const contentWidth = '678px';
export const pageWidthW = '1172px'; // wide version
export const contentWidthW = '850px';
export const navWidth = '310px';

export const color = {
  baseMat: '#fdfdfa',
  accent: '#f5683a',
  main: '#25A298',
  mainThin: '#f5fdfc',
  blue: '#2c67ab',
};
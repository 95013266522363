import React from "react"
import { getMaker } from './datas/makerAll';
import BasicTable from "./basicTable";
import Img from "./image";

const has = (kind) => {
  return kind ? '〇' : 'X';
}

export default function SmallBox(props) {
  const makerInfo = getMaker(props.maker);
  const { 
    hasDesktop,
    hasAllInOne,
    hasTower,
    hasNote,
    hasMobile,
    hasGamingNote,
    name,
    fname,
  } = makerInfo;

  return (
    <>
      <Img {...props} name={name} alt={`${fname}のラインナップ`} />
      <BasicTable className={'center top-margin'}>
        <tbody>
          <tr>
            <th colspan="3">ノート</th>
            <th colspan="3">デスクトップ</th>
          </tr>
          <tr>
            <th>モバイル</th>
            <th>スタンダード</th>
            <th>ゲーミング</th>
            <th>一体型</th>
            <th>スリム型</th>
            <th>タワー型</th>
          </tr>
          <tr>
            <td>{has(hasMobile)}</td>
            <td>{has(hasNote)}</td>
            <td>{has(hasGamingNote)}</td>
            <td>{has(hasAllInOne)}</td>
            <td>{has(hasDesktop)}</td>
            <td>{has(hasTower)}</td>
          </tr>
        </tbody>
      </BasicTable>
    </>
  );
};


import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import List from "components/list";
import { Link, graphql } from "gatsby";
import Card from "components/card";
import SmallBox from 'components/smallBox';
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  AccentBox,
  List,
  Link,
  graphql,
  Card,
  SmallBox,
  React
};
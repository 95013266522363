import Image from "components/image";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import SaleLoadable from "components/saleLoadable";
import NoteMakerRanking from 'components/noteMakerRankingLoadable';
import * as React from 'react';
export default {
  Image,
  Socials,
  SmallBox,
  AccentBox,
  Link,
  graphql,
  SaleLoadable,
  NoteMakerRanking,
  React
};
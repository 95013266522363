import { graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import PassMark from 'components/cpu/passmark';
import * as React from 'react';
export default {
  graphql,
  HikakuTable,
  AccentBox,
  Image,
  Socials,
  PassMark,
  React
};
import React from "react"
import styled from "@emotion/styled"
import BasicTable from './basicTable';
import { mq, contentWidth } from "../utils/style";

const TableScroller = styled.div({
  maxWidth: contentWidth,
  position: 'relative',
  table: {
    display: 'block',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    [mq('mobile')]: {
      lineHeight: '30px',
    },
    'th,td': {
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
      }
    },
    'tr.highlight td': {
        backgroundColor: '#6fe4c5',
    }
  }
});

export default function ScrollTable(props) {
    return (
      <TableScroller>
        <BasicTable className="nb center full-width">
            {props.children}
        </BasicTable>
      </TableScroller>
    );
};
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
import * as React from 'react';
export default {
  Link,
  graphql,
  HikakuTable,
  Accordion,
  AccentBox,
  Socials,
  Meyasu,
  GeforceMatsubi,
  Image,
  React
};
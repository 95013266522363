import React from 'react';
import BasicTable from "./basicTable"

// const fieldArray = ['passmark','vmark','cineR23Single','cineR23Multi','tdp','core', 'thread', 'clock','price', 'cospa'];
const fieldNameMap = {
  name: 'CPU名称',
  pm: 'PassMark',
  pms: 'PassMark(S)',
  c23m: 'C_R23(M)',
  c23s: 'C_R23(S)',
  tdp: 'TDP',
  core: 'コア数',
  thread: 'スレッド数',
  clock: '最大周波数(GHz)',
  year: '発売年',
  price: '価格($)',
  cospa: 'コスパ',
};

const fieldArray = ['name','pm','tdp','core','thread','clock'];

class TableRow extends React.Component {
  render() {
    const { cpu, fieldArray, addUrl } = this.props;
    const keyword = cpu.name.replace(/Intel%20|AMD%20/, '');
    const url = `https://www.amazon.co.jp/s/ref=as_li_ss_tl?k=${keyword}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_1&linkCode=ll2&tag=daigakuseitus-22&linkId=35d63770bb2f4224560575dae588479a&language=ja_JP`;
    return (
      <tr>
        {fieldArray.map(field => {
          switch (field) {
            case 'name':
              return <td key={field}>{addUrl ? <a href={url} target="_blank" rel="nofollow">{cpu.name}</a> : cpu.name}</td>
            default:
              return <td key={field}>{cpu[field] || ''}</td>
          }
        })}
      </tr>
    )
  }
}

export default class CpuTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cpuSubSet: props.cpuData,
    };
    this.fieldArray = props.fieldArray || fieldArray;
  }
  render() {
    return (
      <>
        <BasicTable>
        <thead>
            <tr>
            {this.fieldArray.map(field => {
                return (
                <th key={field}>{fieldNameMap[field]}</th>
                )
            })}
            </tr>
        </thead>
        <tbody>
        {this.state.cpuSubSet.map((cpu, i) => <TableRow cpu={cpu} fieldArray={this.fieldArray} key={cpu.name}/>)}
        </tbody>
        </BasicTable>
      </>
    )
  }
}
import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div({
  padding: '0 0.5em',
  listStyleType: 'none',
  li: {
    position: 'relative',
    padding: '0.5em 1em 0.5em 2.3em',
    marginBottom: '5px',
  },
  'li:after, li:before': {
    content:'""',
    position: 'absolute',
    borderRadius: '50%',
  },
  'li:before': {
    top: '50%',
    left: '0.2em',
    width: '17px',
    height: '17px',
    background: 'rgba(25,118,210, 1)',
    transform: 'translateY(-60%)',
  },
  'li:after': {
    top: '50%',
    left: '0.7em',
    width: '14px',
    height: '14px',
    background: 'rgba(25,118,210, 0.5)',
    transform: 'translateY(-20%)',
  }
});

export default function List(props) {
  return (
    <Wrapper
      className={props.className}
    >
      { props.children }
    </Wrapper>
  );
}
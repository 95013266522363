import React from "react"
import { color } from "../utils/style"
import styled from "@emotion/styled"

let CPU_OBJ;
const CPULIST_PATH = '/data/cpuMegaList.json';
let WAITING_PROMISE = new Promise(() => {});

const Wrapper = styled.div({
    marginBottom: '15px',
});

const Input = styled.input({
  borderRadius: '0px 5px 0px 0px',
  border: `2px solid ${color.blue}`,
  height: '25px',
  width: 'calc(100% - 20px)',
  paddingLeft: '5px',
  '&:focus': {
    outline: 'none',
  }
});

const Badge = styled.div({
    display: 'inline-block',
    padding: '2px 6px',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: color.blue,
    color: color.baseMat,
    fontWeight: 'bold',
});

const RelativeBox = styled.div({
    position: 'relative',
    width: 'calc(100% - 10px)',
});

const AbsoluteBox = styled.div({
    position: 'absolute',
    backgroundColor: color.blue,
    width: '100%',
    color: 'white',
    borderRadius: '0px 0px 5px 5px',
    zIndex: '2',
});

export default class PASSMARKSEARCH extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        filteredCpuList: [],
      }
    }

    filterTable = e => {
        const inputText = e.target.value;
        let filteredCpuList = [];
        if (!inputText) {
            this.setState({filteredCpuList});
            return;
        }
        WAITING_PROMISE.then(() => {
            const regex = new RegExp(inputText, 'i');
            const filteredCpuList = Object.keys(CPU_OBJ).filter(cpuName => {
                return regex.test(cpuName);
            });
            this.setState({filteredCpuList});
        });
    }

    onFocus = () => {
        if (!CPU_OBJ) {
            WAITING_PROMISE = fetch(CPULIST_PATH).then(data => {
                return data.json();
            }).then(data => {
                CPU_OBJ = data;
            });
        }
    }
  
    render () {
        return (
            <Wrapper>
                <Badge>PASSMARK SEARCH</Badge>
                <Input type="text" placeholder="CPUの型番を入力 例)285K,125Hなど" onChange={e => this.filterTable(e)} onFocus={this.onFocus} />
                <RelativeBox>
                    <AbsoluteBox>
                        {this.state.filteredCpuList.map((cpuName, i) => {
                            if (i > 9) {
                                return;
                            }
                            const passMark = CPU_OBJ[cpuName];
                            return <div key={cpuName} style={{padding: '5px 0px 0px 10px'}}>{cpuName}: <span style={{color: color.accent}}>{passMark}点</span></div>
                        })}
                    </AbsoluteBox>
                </RelativeBox>
            </Wrapper>
        );
    }
  }
import React from 'react';
import AccentBox from '../accentBox'
import Accordion from '../accordion'

export default function AmdMatsubi(props) {
  return (
    <Accordion title="CPU性能比較表の読み方。注意・注目点">
      <p>この比較表では、CPU名、ベンチマークスコア、TDP、コア数、スレッド数等の項目が載っているが、それぞれについて注意点を簡単に掲載しておく。表を見て比較する時の参考として欲しい。</p>
      <AccentBox title="CPU性能比較表の読み方。注意・注目点">
        {!props.hidePass && <>
          <li><span className="bold">PassMark(M)</span>・・・絶対的な評価基準、高いほど総合的に優秀。</li>
          <li><span className="bold">PassMark(S)</span>・・・シングルスレッドスコア、高いほどゲームのフレームレートやアプリ起動のサクサク感に効果を発揮</li>
          </>
        }
        {props.cinebench &&
          <li><span className="bold">Cinebench</span>・・・動画像処理に必要なCPUの能力を図るテスト、マルチスレッド性能とシングルスレッド性能を計測。シングルスレッドは高いほどゲームやアプリの起動などに効く。</li>
        }
        <li><span className="bold">TDP</span>・・・熱設計電力を表し、消費電力の目安。単位はW。ノート向けは概ね50W以下で探せば良い。</li>
        <li><span className="bold">コア数</span>・・・CPUの演算ユニットの数、多いほど同時複数の処理を得意とする。</li>
        <li><span className="bold">スレッド数</span>・・・パソコンから見える仮想的なコア数。1コアあたり2スレッドのケースが多いが2コア2スレッドには能力で劣る。</li>
        <li><span className="bold">最大周波数</span>・・・パソコンの速度の基準の一つ、5GHzは1秒間に5億回の計算命令を発行できるタイミングを持つ。命令の効率にも依存するため一概には言えないが、一般的に高いほど単一で重い処理を高速にこなす事ができる。</li>
        <li><span className="bold">価格</span>・・・単位はドル、アメリカでの参考販売価格。日本だとおおよそ130程度を掛けた値となる。輸入価格が上乗せされやや割高な感がある。</li>
        <li><span className="bold">発売年</span>・・・ベンチマークとして初めて登録された年、日本での発売日とは若干ずれる可能性はある。</li>
        <li><span className="bold">コスパ</span>・・・PassMarkの値を価格で割った値。数字が大きいほどコスパが高い。まあ参考程度に。</li>
      </AccentBox>
    </Accordion>
  );
}
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import BasicTable from "components/basicTable";
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
import Twenty from "react-twentytwenty";
import Card from "components/card";
import TOC from "components/toc";
import * as React from 'react';
export default {
  Link,
  graphql,
  HikakuTable,
  Accordion,
  AccentBox,
  BasicTable,
  Socials,
  Meyasu,
  GeforceMatsubi,
  Image,
  Twenty,
  Card,
  TOC,
  React
};
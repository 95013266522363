import React from 'react'
import AccentBox from '../accentBox'

export default function AmdMatsubi() {
  return (
    <AccentBox title="PassMark(M)の目安">
      <li><span className="bold">40000 - </span>・・・ゲームのパフォーマンスに異常にこだわる変態、プロフェッショナルなクリエイティブ用途</li>
      <li><span className="bold">20000 - 40000</span>・・・ゲームで高いFPSを目指す時、クリエイティブ用途で特定処理の高速化を実現したい場合</li>
      <li><span className="bold">12500 - 20000</span>・・・複数アプリでのハードなPC作業も余裕でこなせ速い。高度な3Dゲームを快適にしたい場合</li>
      <li><span className="bold">8000 - 12500</span>・・・日常的なPC使いでは余裕のパフォーマンス、簡単な動画編集や軽いゲームも快適にこなせる</li>
      <li><span className="bold">5500 - 8000</span>・・・日常的なPC使いで遅いとは思わないレベル。テレワークでは最低これぐらいは欲しい</li>
      <li><span className="bold">3000 - 5500</span>・・・体感的な引っかかりが気になるレベル。裏でウイルススキャンとか走ると辛い</li>
      <li><span className="bold">2000 - 3000</span>・・・動画やブラウザでの引っかかりが多く、イライラさせられる。遅い</li>
      <li><span className="bold">~2000</span>・・・敢えて言おう、カスであると</li>
      <div>※ゲームはGPU搭載が前提です。</div>
    </AccentBox>
  )
}


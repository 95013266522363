import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";

const Wrapper = styled.table({
  borderSpacing: '0px',
  borderCollapse: 'collapse',
  td: {
    border: '1px solid #CCC',
  },
  th: {
    border: '1px solid #CCC',
    backgroundColor: color.main,
    color: 'white',
  },
  'tr:nth-of-type(even)': {
    td: {
      backgroundColor: '#f5f5f5',
    }
  },
  'tr:nth-of-type(odd)': {
    td: {
      backgroundColor: 'white',
    }
  },
  '&.nb': { // border-less
    'td,th': {
      border: 'none',
    }
  },
  [mq('mobile')] : {
    width: 'calc(100% - 20px)', // margin
    marginRight: '10px',
    marginLeft: '10px',
    '&.full-width': {
      width: '100%', // margin
      marginRight: '0px',
      marginLeft: '0px',
    }
  },
  [mq('pc')] : {
    width: '100%',
    th : {
      padding: '5px',
    },
    td : {
      padding: '4px',
    }
  },
});

export default function BasicTable(props) {
  return (
    <Wrapper className={props.className}>
      {props.children}
    </Wrapper>
  );
};
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import * as React from 'react';
export default {
  Image,
  TOC,
  SaleLoadable,
  Socials,
  SmallBox,
  AccentBox,
  Link,
  graphql,
  React
};
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import * as React from 'react';
export default {
  Image,
  Link,
  graphql,
  BasicTable,
  React
};
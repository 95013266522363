import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
import * as React from 'react';
export default {
  Image,
  Link,
  graphql,
  BasicTable,
  RaderChart,
  MakerRanking,
  Socials,
  TOC,
  AccentBox,
  SmallBox,
  Lineup,
  React
};
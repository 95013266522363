import React from "react"
import styled from "@emotion/styled"
import { mq, color } from "../utils/style";

const Button = styled.div({
  display: 'inline-block',
  backgroundColor: color.main,/*背景色*/
  color: '#FFF', /*文字色*/
  textDecoration: 'none',
  [mq('mobile')]: {
    fontSize: '1.5rem',/*文字サイズ*/
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  [mq('pc')]: {
    fontSize: '1.7rem', /*文字サイズ*/
    lineHeight: 3,
    marginTop: '1rem',
  },
  '&.small': {
    [mq('mobile')]: {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    [mq('pc')]: {
      lineHeight: '3rem',
    },
  },
  padding: '0px 20px',
  borderRadius: '3px',
  cursor: 'pointer',
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)',
  WebkitTapHighlightColor: 'transparent',
  transition: '.3s ease-out', /*変化を緩やかに*/
  '&:hover' : {
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2)'/*浮き上がるように*/
  }
});

export default function FlatButton(props) {
  return (
    <Button
      onClick={props.onClick}
      className={props.className}
    >
      { props.children }
    </Button>
  );
}
import React from 'react'
import AccentBox from '../accentBox'

export default function IntelMatsubi() {
  return (
    <AccentBox title="Intel CPUの末尾文字">
      <li><span className="bold">アルファベット無し</span>・・・通常版</li>
      <li><span className="bold">X</span>・・・オーバークロックに対応、Kと同じだが、Intelの最高峰CPUを冠した文字</li>
      <li><span className="bold">K</span>・・・オーバークロックに対応</li>
      <li><span className="bold">F</span>・・・CPU内臓グラフィック(iGPU)無効、グラフィックボードを搭載しないと描画できない。デスクトップ向け</li>
      <li><span className="bold">H</span>・・・ハイパフォーマンス、ゲーミングノートに主に使われる</li>
      <li><span className="bold">P</span>・・・第12世代で初登場、ノート用でハイパフォーマンスと低消費電力を両立させた万能タイプ</li>
      <li><span className="bold">G</span>・・・内蔵グラフィック強化版・Adobeなどの動画像編集やゲームで有利</li>
      <li><span className="bold">V</span>・・・Core Ultra 新第２世代から登場。グラフィックも強く、AI用プロセッサーNPUを搭載。知的処理が得意</li>
      <li><span className="bold">T</span>・・・低消費電力版、ノートパソコンや小型デスクトップに使われる、スペック高め</li>
      <li><span className="bold">U</span>・・・15Wウルトラ低消費電力版、事務作業に最適でバッテリー寿命も長い</li>
      <li><span className="bold">Y</span>・・・5Wの超低消費電力版。パワーは弱い、ライトユーザ向け</li>
      <li><span className="bold">M</span>・・・モバイル向け、第５世代以降使われていない</li>
      <li><span className="bold">Q</span>・・・クアッドコア(現在は使われていない)</li>
    </AccentBox>
  )
}
import loadable from '@loadable/component'
const LoadableComponent = loadable(() => import('./saleDefault'));
const LoadableCpuComponent = loadable(() => import('./saleCpu'));
const LoadableGpuComponent = loadable(() => import('./saleGpu'));

export default function Component(props) {
  if (props.cpu) {
    return (
      <LoadableCpuComponent {...props}/>
    )
  }
  if (props.gpu) {
    return (
      <LoadableGpuComponent {...props}/>
    )
  }
  return (
    <LoadableComponent {...props}/>
  )
}
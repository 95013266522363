import React from 'react';
import AccentBox from '../accentBox'

export default function AmdMatsubi() {
  return (
    <AccentBox title="AMD CPUの末尾文字">
      <li><span className="bold">アルファベット無し</span>・・・通常版</li>
      <li><span className="bold">X</span>・・・AMDの最高峰CPUを冠した文字、Intelと同様</li>
      <li><span className="bold">G</span>・・・GPU内臓(AMDのCPUはG以外だとグラフィックボードが必須)</li>
      <li><span className="bold">H</span>・・・ノート用ハイパフォーマンス、ゲーミングノートに主に使われる。</li>
      <li><span className="bold">U</span>・・・低消費電力版、Intelと同様</li>
      <li><span className="bold">E</span>・・・低消費電力版</li>
    </AccentBox>
  );
}
import Image from "components/image";
import TOC from "components/toc";
import { Link, graphql } from "gatsby";
import HikakuTable from 'components/hikakuTableLoadable';
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import Socials from 'components/socials';
import BasicTable from "components/basicTable";
import FlatButton from "components/flatbutton";
import * as React from 'react';
export default {
  Image,
  TOC,
  Link,
  graphql,
  HikakuTable,
  BenchMark,
  CpuMatsubi,
  HowtoFilter,
  TableDescription,
  Socials,
  BasicTable,
  FlatButton,
  React
};
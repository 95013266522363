import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { mq } from "../utils/style";

const Eyecatch = styled.div({
  [mq('mobile')]: {
    width: '100%',
  },
  [mq('pc')]: {
    width: '640px',
    height: '360px',
    margin: 'auto',
  },
});

const Small = styled.div({
  width: '250px',
  height: '200px',
  img: {
    borderRadius: '10px',
  },
  [mq('mobile')]: {
    textAlign: 'center',
    maxWidth: '250px',
    margin: 'auto',
    marginTop: '20px',
  },
  [mq('pc')]: {
    marginLeft: '10px',
    marginBottom: '10px',
    float: 'right',
    img: {
      borderRadius: '10px',
    }
  },
})

const SmallWrapper = styled.div({
  textAlign: 'center',
  color: '#999',
  margin: 'auto',
  [mq('mobile')]: {
    margin: 'auto',
    marginTop: '20px',
  },
  [mq('pc')]: {
    float: 'right',
  },
  a: {
    textDecoration: 'none',
    color: '#999'
  }
});

export default function Image(props) {
  if (!props.fluid && !props.data[props.name]) {
    console.log("image name is looks no image");
    return <></>;
  }
  // console.log("image name is " + props.name);

  const fluid = props.fluid || props.data[props.name].childImageSharp.fluid;
  let { alt = "", fadeIn = true, style = null, link, linkText = "By: amazon.co.jp", caption} = props;
  if (caption) {
    alt = caption;
  }
  const isCover = props.name === "cover";
  const isSmall = props.name.slice(-2) === "_s";

  return (
    <>
      { isCover && <Eyecatch>
          <Img
            fluid={fluid}
            alt={alt}
            fadeIn={false}
            loading={"eager"}
          />
        </Eyecatch>
      }
      { isSmall && <Small style={style}>
          <Img
            fluid={fluid}
            alt={alt}
            fadeIn={fadeIn}
            durationFadeIn={500}
          />
        </Small>}
      { !isCover && !isSmall &&
        <>
          <Img
            fluid={fluid}
            alt={alt}
            fadeIn={fadeIn}
            durationFadeIn={500}
          />
          {link && <div style={{textAlign: 'center', color: '#999'}}>
          <a href={link} target="_blank" rel="nofollow noopenner" style={{textDecoration: 'none', color: '#999'}}>{linkText}</a></div>}
          {caption && <div style={{textAlign: 'center'}}>{caption}</div>}
        </>
      }
    </>
  );
};